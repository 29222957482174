import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Button from "@material-ui/core/Button";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import API from "../api/axios";
import Zoom from "@material-ui/core/Zoom";
import regionesComunas from "../data-utils/regiones-comunas";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactDOM from "react-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Loading from "../components/common/Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fondoImagenUrl = require("../images/fondo_mobile.png");
const iconoCentral = require("../images/icono_central.png");
const logoMercadopago = require("../images/mercadopago.svg");
const pdfCondicionesGarantiaExtendida = require("../pdfs/CERTIFICADO DE COBERTURA (Hurto y Robo)-2024.pdf"); 
const pdfCondicionesRobo = require("../pdfs/CERTIFICADO DE COBERTURA (Hurto y Robo)-2024.pdf");

const iconoFeatures1 = require("../images/icons/011-frame-3.png");
const iconoFeatures2 = require("../images/icons/006-security-1_v2.png");
const iconoFeatures3 = require("../images/icons/002-flying-money-1.png");
const iconoFeatures4 = require("../images/icons/010-shield-1.png");
const iconoFeaturesList = require("../images/icons/004-trusted.png");
const iconoFeaturesCondition = require("../images/icons/check.png");
const iconoFeaturesConditionShield = require("../images/icons/006-shield.png");

var _ = require("lodash");

const styles = (theme) => ({
  fondoImagen: {
    background: `url(${fondoImagenUrl}) no-repeat`,
    backgroundSize: "cover",
    height: "calc(100vh - 126px)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      display: "block",
      position: "fixed",
      top: 0,
      width: "100%",
      backgroundPosition: "center",
    },
  },
  iconoCentral: {
    width: "200px",
    marginLeft: "-100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tituloPrincipal: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "42px",
    lineHeight: "53px",
    color: "#0087C2",
    marginBottom: "3rem",
  },
  labelSelect: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "118.3%",
    color: "#2D2D2D",
    marginBottom: "1rem",
  },
  tituloPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#298DC0",
  },
  textoPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  textoPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  textoPlan2Detalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#2D2D2D",
    marginBottom: "1.5rem",
  },
  precioPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#298DC0",
    marginBottom: "1rem",
  },
  precioPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "26px",
    color: "#111111",
    marginBottom: "0rem",
  },
  tarjetaPlan: {
    marginBottom: "1rem",
    padding: "20px 26px",
    background: "#FFFFFF",
    border: "2px dashed #298DC0",
    boxSizing: "border-box",
    borderRadius: "5px",
    "&:lastChild": { border: "2px solid #298DC0" },
    "&:hover": {
      border: "2px solid #298DC0",
      background: "#298DC0",
      cursor: "pointer",
    },
    "&:hover > p": { color: "#FFFFFF !important" },
    width: "100%",
  },
  containerTab: {
    padding: "10px 7rem 10px 5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 1rem 10px 1rem",
    },
  },
  columnaIzquierda: {
    height: "calc(100vh - 126px)",
    overflow: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "initial",
      overflow: "initial",
      overflowX: "initial",
      backgroundColor: "rgba(255,255,255,1)",
      position: "relative",
      zIndex: "1",
      minHeight: "calc(100vh - 160px)",
    },
  },
  containerGrid: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  precioPlanRef: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "26px",
    color: "#111111",
    marginBottom: "1rem",
  },
  progessBarTop: {
    width: "calc(100% - 20px)",
    border: "1px solid #b0c5db",
    marginTop: "5px",
    height: "10px",
    borderRadius: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "10px",

  },
  activeStep: {
    background: "#b0c5db",
  },
  iconoFeatures: {
    textAlign: "center",
    marginBottom: "15px",
  },
  iconoFeaturesText: {
    padding: "0 1rem",
    lineHeight: "1.2",
    fontSize: "14px",

  },
  listFeatures: {
    // background: "#dfe6ed",
    padding: "5px 15px",
  },
  listFeaturesItemIcon: {
    width: "100%",
  },
  ListFeaturesText: {
    fontSize: "14px",
    paddingLeft: "10px",
    lineHeight: "1.1",
  },
  listFeaturesItem: {

  },
  subTituloPrincipalText: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "1",
    color: "#354851",
    marginBottom: "2rem",
    width: "calc(100% - 20px)",
    padding: "0 10px",
  },
  subTituloPrincipal: {
    color: "#0087C2",
    fontSize: "28px",
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    width: "calc(100% - 20px)",
    padding: "0 10px",
    marginBottom: "0px",
  },
  conditionFeaturesList: {
    background: "#dfe6ed",
    padding: "30px 0 20px 0",
    borderRadius: "30px 0 30px 0",
    marginBottom: "20px",
  },
  conditionFeatureIconShield: {
    width: "40px",
    marginLeft: "5px",
    marginBottom: "30px",
    opacity: "0.9",
  },
  conditionFeatures: {
    marginBottom: "20px",
    width: "calc(100% - 40px)",
    display: "inline-block",
    padding: "0px 20px 0px 20px",
  },
  conditionFeatureIcon: {
    width: "20px",
    float: "left",
    marginRight: "10px",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
  },
  conditionFeatureText: {
    fontSize: "18px",
    display: "block",
    fontWeight: "bold",
    color: "#333",
  },
  iconDowloadDocument: {
    width: "35px",
    marginLeft: "15px",
  },
  checkoutStep: {
    width: "100%",
    background: "#dfe6ed",
    padding: "10px 0",
    marginBottom: "20px",

  },
  checkoutStepIcon: {
    width: "30px",
    marginLeft: "10px",
  },
  checkoutStepText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  homeBoxFeatures: {
    background: "#dfe6ed",
    padding: "10px 0px",
    margin: "0 auto 20px",
    borderRadius: "10px",
    maxWidth: "430px",
  },
  homeInputForm: {
    width: "400px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      minWidth: "360px",
      width: "auto",
    },
  },
  homeButtonForm: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    fontSize: "24px",
    textTransform: "capitalize",
    marginBottom: "2rem",
    alignSelf: "stretch",
    width: "400px",
    margin: "0 auto",
    padding: "1rem 0rem",
    [theme.breakpoints.down("sm")]: {
      minWidth: "360px",
      width: "auto",
    },
  },
  iconmoLogoDemo:{
    width: "20px",
    marginRight: "10px",
  },
});

class Landing1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: false,
      loading: true,
      precio1: "",
      producto: "Teléfonos",
      precio: "",
      productosList: [],
      preciosList: [],
      planes: [],
      activeTab: 1,
      planesEncontrados: [],
      planSeleccionado: undefined,
      regionesList: [],
      comunasList: [],
      testField: "",
      "N° Guia de despacho": "",
      "N° de pedido": "",
      RUT: "",
      Nombres: "",
      "Apellido Paterno": "",
      "Apellido Materno": "",
      "Fecha de Nacimiento": "",
      "Fecha de la boleta":  (new Date()).toISOString().split('T')[0],
      Dirección: "",
      Región: "",
      Comuna: "",
      "Teléfono móvil/fijo": "",
      Email: "",
      condicion1: false,
      condicion2: false,
      condicion3: false,
      condicion4: false,
      isFormValid: false,
      openSnackbar: false,
      mensajesError: [],
      showBotonPagarMercadopago: false,
      campos_formulario_venta: [],
      labelWidth: 0,
      open: false,
      setOpen: false,
      introFormValid : false,
      default_date: (new Date()).toISOString().split('T')[0],
    };
  }
  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
    this.setState({
      regionesList: regionesComunas.regiones.map((e) => e.region),
    });
    API.get("api/landing/losheroes/plans").then((response) => {
      this.setState({ loading: false });
      this.setState({ planes: response.data });
      const productosSet = new Set();
      response.data.forEach((e) => {
        productosSet.add(e.materia.descripcion);
      });
      const productosArray = [];
      productosSet.forEach((e) => {
        productosArray.push(e);
      });
      this.setState({ productosList: productosArray });
    });
  }
  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleChangeProducto(event) {
    this.setState({
      producto: event.target.value,
      precio: "",
      preciosList: [],
    });
    const preciosSet = new Set();
    this.state.planes
      .filter(
        (e) =>
          e.materia.descripcion == event.target.value &&
          e.name.includes("desde") &&
          e.name.includes("hasta")
      )
      .map((e) => e.name.slice(e.name.indexOf("desde")))
      .forEach((e) => {
        preciosSet.add(e);
      });
    const preciosArray = [];
    preciosSet.forEach((e) => {
      preciosArray.push(e);
    });
    this.setState({ preciosList: preciosArray });
  }
  handleChangePrecio(event) {
    console.log({ precio: event.target.value });
    this.setState({ precio: event.target.value });
  }
  toFirstTab() {
    console.log("toFirstTab");
    this.setState({ activeTab: 1 });
  }
  toSecondTab() {
    this.setState({ loading: true });
    console.log("toSecondTab");
    if (this.state.precio1 && this.state.precio1 < 1 ) {
      const mensajesError = [
        {
          active: true,
          message:
            "El valor del equipo debe ser mayor a $0.",
        },
      ];
      this.setState({ mensajesError });
    }
    else if (this.state.producto && this.state.precio1) {
      this.sendIntroForm();
      this.setState(
        {
          // planesEncontrados: this.state.planes
          //   .filter((e) => {
          //     if (!JSON.parse(e.filters)) return false;
          //     if (!JSON.parse(e.filters).campos_range.length) return false;
          //     return (
          //       e.materia.descripcion == this.state.producto &&
          //       parseFloat(this.state.precio1) >=
          //       parseFloat(
          //         JSON.parse(e.filters).campos_range[0].plan_desde
          //       ) &&
          //       parseFloat(this.state.precio1) <=
          //       parseFloat(JSON.parse(e.filters).campos_range[0].plan_hasta)
          //     );
          //   })
          planesEncontrados: this.state.planes
            .filter((e) => {
              if (!JSON.parse(e.filters)) return false;
              if (!JSON.parse(e.filters).campos_range.length) return false;
              return (
                parseFloat(this.state.precio1) >=
                parseFloat(
                  JSON.parse(e.filters).campos_range[0].plan_desde
                ) &&
                parseFloat(this.state.precio1) <=
                parseFloat(JSON.parse(e.filters).campos_range[0].plan_hasta)
              );
            })
            .map((e) => ({
              ...e,
              isActive: !this.state.planSeleccionado
                ? false
                : this.state.planSeleccionado.id == e.id,
            })),
        },
        // Luego de esperar que actulize el state
        () => {
          if (this.state.planesEncontrados.length == 1) {
            console.log("this.state.planesEncontrados", this.state.planesEncontrados);
            console.log("this.state.planesEncontrados 1", this.state.planesEncontrados[0]);
            this.seleccionarPlan(this.state.planesEncontrados[0], false);
          }
          else {
            if (this.state.planesEncontrados.length) {
              this.setState({ activeTab: 2 });
            } else {
              const mensajesError = [
                {
                  active: true,
                  message:
                    "No encontramos resultados. Intenta con otros valores.",
                },
              ];
              this.setState({ mensajesError });
            }
          }
        }
      );
    }
    this.setState({ loading: false });
  }
  seleccionarPlan(plan, multiple = true) {
    console.log("seleccionarPlan", plan);
    // this.setState({ loading: true });
    this.setState({
      planesEncontrados: this.state.planesEncontrados.map((e) => {
        e.isActive = plan.id == e.id;
        return e;
      }),
    });
    let atributosMateriaTotal = JSON.parse(plan.materia.atributos);
    console.log("atributosMateriaTotal", atributosMateriaTotal);
    let atributosMateria = [];
    // Campos provenientes de la materia
    atributosMateriaTotal.forEach((a) => {
      if (a.momento == "venta") {
        atributosMateria.push({
          name: _.capitalize(a.nombre),
          attr_type: "materia",
          data_type: "string",
        });
      }
    });
    // Campos provenientes de la subcategoria (para filtrar en el POS en vista de nueva venta)
    plan.plansubcategoria.searchablesattrs.forEach((sa) => {
      atributosMateria.push({
        name: _.capitalize(sa.public_name),
        attr_type: "subcategoria",
        data_type: sa.data_type_input,
      });
    });
    //console.log('atributosMateria', atributosMateria);
    let obj = new Object();
    atributosMateria.forEach((am) => {
      if (am.name.toLowerCase() == "el valor del equipo") {
        obj[am.name] = this.state.precio1;
      } else {
        obj[am.name] = "";
      }
    });

    if (multiple == false) {
      this.setState({ ...obj }, () => {
        this.setState({ campos_formulario_venta: atributosMateria, planSeleccionado: plan }, () => {
          console.log("seleccionarPlan 2", plan);
          console.log("this.state.planSeleccionado 0 ", this.state.planSeleccionado);
          this.toThirdTab();

        });
      });
    }
    else {
      //console.log('obj', obj);
      this.setState({ ...obj }, () => {
        this.setState({ campos_formulario_venta: atributosMateria });
        this.setState({ planSeleccionado: plan });
      });
      this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }

  }
  toThirdTab() {
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 3 });
    }
  }
  toForthTab() {
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 4 });
    }
  }
  handleChangeTextField(event, property) {
    let obj = new Object();
    // console.log("property", property);
    // console.log("event.target.value", event.target.value);
    // console.log(this.state.campos_formulario_venta);
    // this.state.campos_formulario_venta.every((e) => console.log(this.state[e.name]));
    
    obj[property] = event.target.value;
    this.setState({ ...obj });

    if (property == "Email") {
      if ( /\S+@\S+\.\S+/.test(event.target.value) ) {
        this.setState({ introFormValid: true });
      }
      else{
        this.setState({ introFormValid: false });
      }
    }

      console.log(this.state["Email"]);
      console.log(this.state["Fecha de la boleta"]);
      console.log(this.state.condicion1 );
      console.log(this.state.campos_formulario_venta.every((e) => !!this.state[e.name]))
      console.log(this.state.campos_formulario_venta);
      console.log(this.state.campos_formulario_venta.map((e) => e.name));
      console.log(this.state.campos_formulario_venta.map((e) => this.state[e.name]));
      // console.log(this.state["N° de pedido"]);
      console.log(this.state.default_date);
      if (this.state["Fecha de la boleta"] == '') {
        this.setState({ "Fecha de la boleta": this.state.default_date });
      }
      if (this.state["Fecha de compra"] == '') {
        this.setState({ "Fecha de compra": this.state.default_date });
      }
      console.log(this.state["Fecha de compra"]);



  }
  handleChangeCheckbox(event, property) {
    let obj = new Object();
    obj[property] = event.target.checked;
    this.setState({ ...obj });
  }
  handleChangeRegion(event) {
    this.setState({ Región: event.target.value, Comuna: "", comunasList: [] });
    this.setState({
      comunasList: regionesComunas.regiones.find(
        (e) => e.region == event.target.value
      ).comunas,
    });
  }
  handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();
    console.log("onSubmit");
    this.setState({ isLoading: true }, () => this.sendForm());
  }
  sendForm() {
    return new Promise((resolve, eject) => {
      const formData = _.pick(
        this.state,
        [
          "N° Guia de despacho",
          "N° de pedido",
          "RUT",
          "Nombres",
          "Apellido Paterno",
          "Apellido Materno",
          "Fecha de Nacimiento",
          "Dirección",
          "Región",
          "Comuna",
          "Teléfono móvil/fijo",
          "Email",
          "planSeleccionado",
        ].concat(
          this.state.campos_formulario_venta.map((cf) => cf.name),
          ["campos_formulario_venta"]
        )
      );
      API({
        url: "api/v1/venta",
        method: "POST",
        data: formData,
      })
        .then(async (response) => {
          //console.log(response.data);
          await global.config.mercadopago.checkout({
            preference: {
              id: response.data.preference_id,
            },
            theme: {
              elementsColor: "#0087C2",
              headerColor: "#354851",
            },
            autoOpen: true,
          });
          this.props.dispatch({
            type: "SET_PREFERENCE_ID",
            value: response.data.preference_id,
          });
          this.setState({ loading: false });
          this.setState(
            { showBotonPagarMercadopago: true, isLoading: false },
            () => {
              resolve();
            }
          );
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
          this.setState({ isLoading: false });
          if (error.response.status == 422) {
            if (error.response.data) {
              if (error.response.data.errors) {
                if (Object.keys(error.response.data.errors).length < 1) return;
                const mensajesError = Object.keys(
                  error.response.data.errors
                ).map((key) => {
                  return {
                    active: true,
                    message: error.response.data.errors[key][0],
                  };
                });
                this.setState({ mensajesError }, () => {
                  eject();
                });
                return;
              }
            }
          }
          const mensajesError = [
            {
              active: true,
              message:
                "Ha ocurrido un error en el servidor. Reintente mas tarde.",
            },
          ];
          this.setState({ mensajesError }, () => {
            eject();
          });
        });
    });
  }
  sendIntroForm() {

    var introForm = {
      "producto": this.state.producto,
      "precio1": this.state.precio1,
    }
    API({
      url: "api/v2/introform",
      method: "POST",
      data: 
        {
          "landing": "losheroes",
          "email": this.state["Email"],
          "info": JSON.stringify(introForm)
        }
      
    })
  }
  handleClickBotonPagarMercadopago() {
    this.setState({ isLoading: true }, () => {
      this.sendForm();
    });
  }
  handleClickBotonPagar() {
    this.setState({ loading: true });
    this.setState({ isLoading: true }, () => {
      this.sendForm();
    });
  }
  handleCloseSnackbar(index) {
    let mensajesError = this.state.mensajesError.map((e, i) => {
      if (i == index) {
        return { ...e, active: false };
      } else {
        return e;
      }
    });
    this.setState({ mensajesError: mensajesError });
  }
  openInNewTab(url){
    console.log("url", url);
    window.open(url, '_blank', 'noreferrer');
  }
  render() {
    const { classes } = this.props;
    const formValid =
      !this.state.isLoading &&
      !!this.state["RUT"] &&
      !!this.state["Nombres"] &&
      !!this.state["Apellido Paterno"] &&
      !!this.state["Apellido Materno"] &&
      !!this.state["Fecha de Nacimiento"] &&
      !!this.state["Dirección"] &&
      !!this.state["Región"] &&
      !!this.state["Comuna"] &&
      !!this.state["Teléfono móvil/fijo"] &&
      !!this.state["Email"] &&
      !!this.state.condicion1  &&
      // !!this.state.condicion2 &&
      // !!this.state.condicion3 &&
      // !!this.state.condicion4 &&
      this.state.campos_formulario_venta.every((e) => !!this.state[e.name])
      ;
    let pdfTerminosCondiciones = "";
    if (this.state.planSeleccionado) {
      pdfTerminosCondiciones = this.state.planSeleccionado.name
        .toLowerCase()
        .includes("robo")
        ? pdfCondicionesRobo
        : pdfCondicionesGarantiaExtendida;
    } else {
      pdfTerminosCondiciones = "http://www.maconline.cl";
    }
    return (
      <>
        <Loading loading={this.state.loading} />
        <Loading loading={this.state.loading} />
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle disableTypography>
            <Typography variant="subtitle1" gutterBottom>
            Para conocer tu número IMEI puedes digitar *#06#* en el teléfono. Aparecerá una pantalla con esta y otras informaciones sobre el equipo.
            <br></br>
            <br></br>
            También puedes copiar y pegar el número IMEI de manera sencilla, dirígete a:
            <br></br>
            1-Ajustes de teléfono
            <br></br>
            2-Acerca del teléfono
            <br></br>
            3- Ubica el numero IMEI y mantén presionado unos segundos.
            <br></br>
            <br></br>
            Puedes ver los videos a modo de ejemplo:
            </Typography>
            <Typography variant="contained" gutterBottom>
              <Button 
              color="primary"
              style={{
                marginRight: "5px",
                fontSize: "12px",
                marginBottom: "5px",
              }}
              variant="contained"
              onClick={() => this.openInNewTab('https://garnetek.s3.us-west-1.amazonaws.com/mobile/IMEI_IPHONE.mp4')}

              >
                <img
              className={classes.iconmoLogoDemo}
              src={require("../images/logo_ios.png")}
              alt="icono"
            ></img>

                  Ver Video
              </Button>

              <Button 
              color="primary"
              style={{
                fontSize: "12px",
                marginBottom: "5px",
              }}
              variant="contained"
              onClick={() => this.openInNewTab('https://garnetek.s3.us-west-1.amazonaws.com/mobile/IMEI_ANDROID.mp4')}
              >
                <img
              className={classes.iconmoLogoDemo}
              src={require("../images/logo_android.png")}
              alt="icono"
              
            ></img>

                  Ver Video
              </Button>
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Typography variant="subtitle1" gutterBottom>
              Si lo compraste en tienda tienes que ingresar el número de tu
              boleta pero si lo compraste por internet debes ingresar el N° de
              Pedido.
            </Typography>
          </DialogTitle>
          <DialogTitle id="alert-dialog-slide-title">
            {"¿Cómo obtengo el N° de pedido?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography variant="subtitle1" gutterBottom>
                Mail:
              </Typography>
              <img
                style={{ width: "100%" }}
                src={require("../images/mail_n_pedido.png")}
              ></img>
              <Typography variant="subtitle1" gutterBottom>
                Mi cuenta:
              </Typography>
              <img
                style={{ width: "100%" }}
                src={require("../images/micuenta_n_pedido.png")}
              ></img>
              <Typography variant="subtitle1" gutterBottom>
                Pago exitoso (página posterior a la compra):
              </Typography>
              <img
                style={{ width: "100%" }}
                src={require("../images/pago_exitoso_n_pedido.png")}
              ></img>
              <Typography variant="subtitle1" gutterBottom>
                Detalle del pedido en sección "Mi cuenta":
              </Typography>
              <img
                style={{ width: "100%" }}
                src={require("../images/detalle_n_pedido.png")}
              ></img>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog> */}
        <Topbar />
        <Grid className={classes.containerGrid} container spacing={0}>
          <Grid
            className={classes.columnaIzquierda}
            item
            xs={12}
            sm={12}
            md={6}
          >
            {this.state.activeTab === 1 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>

                  <Grid className={classes.progessBarTop} container>
                    <Grid xs={6} sm={6} md={6} item className={classes.activeStep} ></Grid>
                    <Grid xs={6} sm={6} md={6} item ></Grid>
                  </Grid>

                  <Typography className={classes.tituloPrincipal} style={{ textAlign: "center" }} >
                    Despreocúpate y disfruta tu equipo
                  </Typography>

                  <Grid container>

                    <Grid xs={12} sm={12} md={12} item className={classes.iconoFeatures}> <img src={iconoFeatures2} alt="icono"></img><Typography className={classes.iconoFeaturesText}>Robo</Typography></Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={6} sm={6} md={6} item className={classes.iconoFeatures}> <img src={iconoFeatures3} alt="icono"></img><Typography className={classes.iconoFeaturesText}>Gastos generados</Typography></Grid>
                    <Grid xs={6} sm={6} md={6} item className={classes.iconoFeatures}> <img src={iconoFeatures4} alt="icono"></img><Typography className={classes.iconoFeaturesText}>Protegido</Typography></Grid>
                  </Grid>

                  <div className={classes.homeBoxFeatures}>
                    <Typography className={classes.tituloPrincipal} style={{ textAlign: "center", marginBottom: "10px", fontSize: "22px", lineHeight: "1", marginTop: "5px" }} >
                      ¿Por qué contratar Seguro para tu Equipo?
                    </Typography>
                    <Grid container className={classes.listFeatures}>
                      <Grid xs={1} sm={1} md={1} item className={classes.listFeaturesItem}> <img src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                      <Grid xs={11} sm={11} md={11} item className={classes.listFeaturesItem} ><Typography className={classes.ListFeaturesText}>Tu equipo es tu inversión y el riesgo de robo es evidente.</Typography></Grid>
                    </Grid>
                    <Grid container className={classes.listFeatures}>
                      <Grid xs={1} sm={1} md={1} item className={classes.listFeaturesItem}> <img src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                      <Grid xs={9} sm={9} md={9} item className={classes.listFeaturesItem} ><Typography className={classes.ListFeaturesText}>Por un solo pago quedas protegido por 1 año contra robo.</Typography></Grid>
                    </Grid>
                    <Grid container className={classes.listFeatures}>
                      <Grid xs={1} sm={1} md={1} item className={classes.listFeaturesItem}> <img src={iconoFeaturesList} className={classes.listFeaturesItemIcon} alt="icono"></img></Grid>
                      <Grid xs={9} sm={9} md={9} item className={classes.listFeaturesItem} ><Typography className={classes.ListFeaturesText}>Tu Smartphone puede ser nuevo o usado e igual lo puede asegurar.</Typography></Grid>
                    </Grid>

                  </div>

                  <Typography className={classes.subTituloPrincipal} style={{ textAlign: "center" }}>
                    Cuéntanos sobre tu Equipo
                  </Typography>
                  <Typography className={classes.subTituloPrincipalText} style={{ textAlign: "center" }}>
                    Cotiza en menos de 3 minutos, estas en el paso 1 de 2
                  </Typography>

{/* 
                  <FormControl
                    className={classes.homeInputForm}
                    style={{
                      marginBottom: "1rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                  >
                    <InputLabel
                      ref={(ref) => {
                        this.InputLabelRef = ref;
                      }}
                      id="select-producto-label"
                    >
                      ¿Cuál es el modelo de tu equipo?
                    </InputLabel>
                    <Select
                      value={this.state.producto}
                      onChange={(event) => this.handleChangeProducto(event)}
                      input={
                        <OutlinedInput labelWidth={this.state.labelWidth} />
                      }
                    >
                      {this.state.productosList.map((e) => (
                        <MenuItem key={e} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}

                  <FormControl
                    className={classes.homeInputForm}
                    style={{
                      marginBottom: "1rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                  >
                    <TextField
                      ref={(ref) => {
                        this.InputLabelRef = ref;
                      }}
                      label="¿Cuál es el Valor de tu equipo?"
                      defaultValue={this.state["precio1"]}
                      onChange={(e) => this.handleChangeTextField(e, "precio1")}
                      variant="outlined"
                      type="number"
                      onKeyDown={(e) =>
                        (e.key == "," || e.key == ".") && e.preventDefault()
                      }
                    />
                  </FormControl>

                  <FormControl
                    className={classes.homeInputForm}
                    style={{
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                  >
                    <TextField
                      label="Correo de Contacto"
                      defaultValue={this.state["Email"]}
                      onChange={(e) =>
                        this.handleChangeTextField(e, "Email")
                      }
                      variant="outlined"
                      type="email"
                    />
                  </FormControl>

                  <Button
                    onClick={() => this.toSecondTab()}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                    }}
                    variant="contained"
                    className={classes.homeButtonForm}
                    disabled={!this.state.precio1 || !this.state.Email || !this.state.introFormValid}
                  >
                    Iniciar Cotización
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 2 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                  <Button
                    onClick={() => this.toFirstTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "2rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Volver atrás
                  </Button>
                  <Typography className={classes.tituloPrincipal}>
                    Encontramos {this.state.planesEncontrados.length} seguro
                    {this.state.planesEncontrados.length === 1 ? "" : "s"} para
                    tu producto:
                  </Typography>
                  <>
                    {this.state.planesEncontrados.map((e, i) => (
                      <div
                        onClick={() => this.seleccionarPlan(e)}
                        key={i}
                        className={`${classes.tarjetaPlan} ${e.isActive ? "plan-active" : ""
                          }`}
                      >
                        <Typography
                          style={{ marginBottom: "25px" }}
                          className={classes.tituloPlan}
                        >
                          {e.name}
                        </Typography>
                        <Typography className={classes.textoPlan}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e.consideraciones,
                            }}
                          />
                        </Typography>
                        {/* <Typography className={classes.precioPlan}>
                          ${" "}
                          {parseFloat(
                            e.valor_clp_inseguro
                              ? e.valor_clp_inseguro
                              : e.formula
                          ).toLocaleString("de-DE")}{" "}
                          Pesos
                        </Typography> */}

                        <Typography className={classes.precioPlan} style={{ marginBottom: "0" }} >
                          Valor: ${parseFloat(e.pricePesos).toLocaleString("de-DE")} <span style={{ fontSize: "14px" }}>mensual</span>
                        </Typography>
                        {/* <Typography className={classes.precioPlanRef}>
                          (
                          {parseFloat(
                            e.valor_clp_inseguro
                              ? e.valor_clp_inseguro
                              : e.formula
                          ).toLocaleString("de-DE")}{" "}  UF)
                          <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(e.valueUf).toLocaleString("es")} al {e.dateUf})</small>
                        </Typography> */}

                      </div>
                    ))}
                  </>
                  <Button
                    ref={this.myRef}
                    onClick={() => this.toThirdTab()}
                    disabled={!this.state.planSeleccionado}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                    }}
                    variant="contained"
                  >
                    Continuar
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 3 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                  <Grid className={classes.progessBarTop} container>
                    <Grid xs={6} sm={6} md={6} item className={classes.activeStep}></Grid>
                    <Grid xs={6} sm={6} md={6} item  className={classes.activeStep}></Grid>
                  </Grid>


                  <Button
                    onClick={() => this.toFirstTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "0rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Paso 2 de 2 de la cotización
                  </Button>
                  <Typography className={classes.tituloPrincipal} style={{ fontSize: "48px" }}>
                    Este plan tenemos para ti
                  </Typography>
                  {/* <Typography className={classes.tituloPrincipal}>
                    {this.state.planSeleccionado.name}
                  </Typography>
                  <Typography className={classes.textoPlanDetalle}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.planSeleccionado.condiciones,
                      }}
                    />
                  </Typography> */}
                  {/* <Typography className={classes.precioPlanDetalle}>
                    ${" "}
                    {parseFloat(
                      this.state.planSeleccionado.valor_clp_inseguro
                        ? this.state.planSeleccionado.valor_clp_inseguro
                        : this.state.planSeleccionado.formula
                    ).toLocaleString("de-DE")}{" "}
                    Pesos
                  </Typography> */}


                  <div className={classes.conditionFeaturesList}>
                    <img src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono" style={{ marginLeft: "20px" }}></img>
                    <img src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono"></img>
                    <img src={iconoFeaturesConditionShield} className={classes.conditionFeatureIconShield} alt="icono"></img>


                    <div className={`${classes.conditionFeatures}`}>
                      <img src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                      <div className={classes.conditionFeatureText}> Cobertura de robo.</div>
                    </div>
                    <div className={`${classes.conditionFeatures}`}>
                      <img src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                      <div className={classes.conditionFeatureText}> ¿Te encanta tu Equipo? Ahora quedará protegido.</div>
                    </div>
                    <div className={`${classes.conditionFeatures}`}>
                      <img src={iconoFeaturesCondition} className={classes.conditionFeatureIcon} alt="icono"></img>
                      <div className={classes.conditionFeatureText}> Sin deducible
                        <small style={{ fontWeight: "normal" }}> No debes pagar nada en caso de robo</small></div>
                    </div>


                    <Typography className={classes.precioPlanDetalle} style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "20px" }}>
                      Valor: ${parseFloat(this.state.planSeleccionado.pricePesos).toLocaleString("de-DE")} <span style={{ fontSize: "18px" }}></span>
                    </Typography>
                    {/* <Typography className={classes.precioPlanRef} style={{ marginBottom: "25px", paddingLeft: "20px", paddingRight: "20px" }}>
                      ( {parseFloat(
                        this.state.planSeleccionado.valor_clp_inseguro
                          ? this.state.planSeleccionado.valor_clp_inseguro
                          : this.state.planSeleccionado.formula
                      ).toLocaleString("de-DE")}  UF)
                      <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(this.state.planSeleccionado.valueUf).toLocaleString("es")} al {this.state.planSeleccionado.dateUf})</small>
                    </Typography> */}



                    {/* <Typography className={classes.textoPlan2Detalle}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.planSeleccionado.consideraciones,
                      }}
                    />
                  </Typography> */}



                  </div>



                  {/* <Typography className={classes.precioPlanDetalle}>
                    Valor: ${parseFloat(this.state.planSeleccionado.pricePesos).toLocaleString("de-DE")} <span style={{ fontSize: "18px" }}>mensual</span>
                  </Typography>
                  <Typography className={classes.precioPlanRef} style={{ marginBottom: "25px" }}>
                    ( {parseFloat(
                      this.state.planSeleccionado.valor_clp_inseguro
                        ? this.state.planSeleccionado.valor_clp_inseguro
                        : this.state.planSeleccionado.formula
                    ).toLocaleString("de-DE")}  UF)
                    <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(this.state.planSeleccionado.valueUf).toLocaleString("es")} al {this.state.planSeleccionado.dateUf})</small>
                  </Typography>

                  <Typography className={classes.textoPlan2Detalle}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.planSeleccionado.consideraciones,
                      }}
                    />
                  </Typography>
                  <Link
                    target="_blank"
                    href={
                      this.state.planSeleccionado.file_url
                        ? this.state.planSeleccionado.file_url
                        : pdfTerminosCondiciones
                    }
                    style={{ display: "flex" }}
                    className={classes.textoPlan2Detalle}
                  >
                    <CloudDownload
                      style={{ marginRight: "10px" }}
                    ></CloudDownload>{" "}
                    Descargar documento
                  </Link> */}
                  <Button
                    onClick={() => this.toForthTab()}
                    disabled={!this.state.planSeleccionado}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                    }}
                    variant="contained"
                  >
                    Activar mi Protección
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 4 && (
              <Zoom in={true} timeout={400}>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  <div className={classes.containerTab}>
                    <Grid className={classes.progessBarTop} container>
                      <Grid xs={6} sm={6} md={6} item className={classes.activeStep}></Grid>
                      <Grid xs={6} sm={6} md={6} item  ></Grid>
                    </Grid>

                    <Button
                      onClick={() => this.toThirdTab()}
                      style={{
                        marginBottom: "1rem",
                        marginTop: "0rem",
                        color: "#878787",
                        fontFamily: "Roboto",
                      }}
                    >
                      <ArrowBack></ArrowBack> Estás en el paso 1 de 2 de la contratación
                    </Button>


                    <Typography className={classes.tituloPrincipal}>
                      Completa tu protección con estos datos
                    </Typography>

                    <Grid container className={classes.checkoutStep}>
                      <Grid item xs={2} sm={2} md={2}>
                        <img
                          src={require("../images/icons/002-lock.png")}
                          className={classes.checkoutStepIcon}
                          alt="icono"
                        ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos Personales
                        </Typography>
                      </Grid>
                    </Grid>


                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["RUT"]}
                          onChange={(e) => this.handleChangeTextField(e, "RUT")}
                          label="RUT (sin puntos y con guión)"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Nombres"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Nombres")
                          }
                          label="Nombres"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Paterno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Paterno")
                          }
                          label="Apellido Paterno"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Materno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Materno")
                          }
                          label="Apellido Materno"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          defaultValue={this.state["Fecha de Nacimiento"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Fecha de Nacimiento")
                          }
                          label="Fecha de Nacimiento"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Dirección"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Dirección")
                          }
                          label="Dirección"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-region-label">
                            Región
                          </InputLabel>
                          <Select
                            label="Región"
                            id="select-region"
                            value={this.state["Región"]}
                            onChange={(e) => this.handleChangeRegion(e)}
                          >
                            {this.state.regionesList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-comuna-label">
                            Comuna
                          </InputLabel>
                          <Select
                            label="Comuna"
                            id="select-comuna"
                            value={this.state["Comuna"]}
                            onChange={(e) =>
                              this.handleChangeTextField(e, "Comuna")
                            }
                          >
                            {this.state.comunasList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>


                    <Grid container className={classes.checkoutStep}>
                      <Grid item xs={2} sm={2} md={2}>
                        <img
                          src={require("../images/icons/008-user.png")}
                          className={classes.checkoutStepIcon}
                          alt="icono"
                        ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos de Contacto
                        </Typography>
                      </Grid>
                    </Grid>


                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Teléfono móvil/fijo"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Teléfono móvil/fijo")
                          }
                          label="Teléfono móvil/fijo"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "1rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={6}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Email"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Email")
                          }
                          label="Email"
                          type="email"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid container className={classes.checkoutStep}>
                      <Grid item xs={2} sm={2} md={2}>
                        <img
                          src={require("../images/icons/008-user.png")}
                          className={classes.checkoutStepIcon}
                          alt="icono"
                        ></img>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <Typography className={classes.checkoutStepText}>
                          Datos del Equipo
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                      {this.state.campos_formulario_venta.map((am, ind) => {
                        if (am.name == "N° de pedido") {
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={12}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                  ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                  ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item>
                                  <Tooltip title="¿Cómo lo obtengo?">
                                    <IconButton
                                      onClick={() => this.handleClickOpen()}
                                      aria-label="¿Cómo lo obtengo?"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  style={{ flexGrow: "1", cursor: "pointer" }}
                                >
                                  <TextField
                                    placeholder="N° de pedido (R123456789) o N° de boleta (7-9 digitos)"
                                    style={{ width: "100%" }}
                                    type={
                                      am.data_type == "int" ? "number" : "text"
                                    }
                                    defaultValue={this.state[am.name]}
                                    onChange={(e) =>
                                      this.handleChangeTextField(e, am.name)
                                    }
                                    onKeyDown={(e) =>
                                      am.data_type == "int" &&
                                      (e.key == "," || e.key == ".") &&
                                      e.preventDefault()
                                    }
                                    label={"N° de pedido o N° de boleta"}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                        if (am.name == "Fecha de la boleta" || am.name == "Fecha de compra") {
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={6}
                              sm={
                                (ind + 1) % 2 === 1 &&
                                  ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                              md={
                                (ind + 1) % 2 === 1 &&
                                  ind + 1 ==
                                  this.state.campos_formulario_venta.length
                                  ? 12
                                  : 6
                              }
                            >
                              <TextField
                                InputLabelProps={{ shrink: true, required: true  }}
                                type="date"
                                // defaultValue={(new Date()).toISOString().split('T')[0]}
                                defaultValue={this.state.default_date}
                                onChange={(e) =>
                                  this.handleChangeTextField(e, am.name)
                                }
                                label={am.name}
                                variant="outlined"
                              />
                            </Grid>
                          );
                        }

                        if (am.name == "Imei") {
                          return (
                            <Grid
                              key={ind}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "1rem",
                                marginBottom: "1rem",
                              }}
                              item
                              xs={12}
                              sm={
                               12
                              }
                              md={
                                12
                              }
                            >
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item>
                                  <Tooltip title="¿Cómo lo obtengo?">
                                    <IconButton
                                      onClick={() => this.handleClickOpen()}
                                      aria-label="¿Cómo lo obtengo?"
                                    >
                                      <HelpIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  style={{ flexGrow: "1", cursor: "pointer" }}
                                >
                                  <TextField
                                    placeholder="Imei"
                                    style={{ width: "100%" }}
                                    type={
                                      am.data_type == "int" ? "number" : "text"
                                    }
                                    defaultValue={this.state[am.name]}
                                    onChange={(e) =>
                                      this.handleChangeTextField(e, am.name)
                                    }
                                    onKeyDown={(e) =>
                                      am.data_type == "int" &&
                                      (e.key == "," || e.key == ".") &&
                                      e.preventDefault()
                                    }
                                    label={"Imei"}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }

                        if (am.name == "El valor del equipo") {
                          return (
                            <Grid
                            key={ind}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingRight: "1rem",
                              marginBottom: "1rem",
                            }}
                            item
                            xs={6}
                            sm={
                              6
                            }
                            md={
                              6
                            }
                          >
                            <TextField
                              type={am.data_type == "int" ? "number" : "text"}
                              defaultValue={this.state[am.name]}
                              onChange={(e) =>
                                this.handleChangeTextField(e, am.name)
                              }
                              onKeyDown={(e) =>
                                am.data_type == "int" &&
                                (e.key == "," || e.key == ".") &&
                                e.preventDefault()
                              }
                              label={am.name}
                              variant="outlined"
                            />
                          </Grid>
                          );
                        }
                        // if (am.name == "Sku producto" || am.name == "Numero de boleta") {
                        //   return ("");
                        // }

                        return (
                          <Grid
                            key={ind}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingRight: "1rem",
                              marginBottom: "1rem",
                            }}
                            item
                            xs={6}
                            sm={
                              (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                this.state.campos_formulario_venta.length
                                ? 12
                                : 6
                            }
                            md={
                              (ind + 1) % 2 === 1 &&
                                ind + 1 ==
                                this.state.campos_formulario_venta.length
                                ? 12
                                : 6
                            }
                          >
                            <TextField
                              type={am.data_type == "int" ? "number" : "text"}
                              defaultValue={this.state[am.name]}
                              onChange={(e) =>
                                this.handleChangeTextField(e, am.name)
                              }
                              onKeyDown={(e) =>
                                am.data_type == "int" &&
                                (e.key == "," || e.key == ".") &&
                                e.preventDefault()
                              }
                              label={am.name}
                              variant="outlined"
                            />
                          </Grid>
                        );
                      })}
                    </Grid>

                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion1}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion1")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ paddingTop: "10px", marginBottom: "1rem" }}
                      >
                        <Link
                          style={{
                            textDecoration: "underline",
                            fontFamily: "Source Sans Pro",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                          href={pdfTerminosCondiciones}
                          target="_blank"
                        >
                          He leído y comprendo las condiciones generales y
                          particulares{" "}
                        </Link>
                        <Typography
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}>
                          
                          Autorizo que el cobro de la prima de este seguro se realice en la tarjeta de crédito indicada y que las comunicaciones relacionadas al seguro se envíen al correo electrónico informado. En caso de desconocerse el correo electrónico o no ser efectivo el envío, autorizo a que las comunicaciones me sean enviadas al correo electrónico que he informado al contratante de la póliza colectiva
                          Autorizo aMapfre Compañía de Seguros Generales de Chile S.A para que pueda efectuar el tratamiento y almacenamiento de mis datos personales proporcionados en esta contratación del Seguro y puedan ser utilizados en la administración, análisis, cumplimiento y/o auditoría del mismo.
                          Declaro que acepto voluntariamente la contratación de este seguro
                        </Typography>
                        <p></p>
                      </Grid>
                    </Grid>
                    {/* <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion2}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion2")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Autorizo que el cobro de la prima de este seguro se
                        realice en la tarjeta de crédito indicada y que las
                        comunicaciones relacionadas al seguro se envíen al
                        correo electrónico informado. En caso de desconocerse el
                        correo electrónico o no ser efectivo el envío, autorizo
                        a que las comunicaciones me sean enviadas al correo
                        electrónico que he informado al contratante de la póliza
                        colectiva
                      </Grid>
                    </Grid>
                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion3}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion3")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Autorizo aMapfre Compañía de Seguros Generales de Chile S.A para que pueda
                        efectuar el tratamiento y almacenamiento de mis datos
                        personales proporcionados en esta contratación del
                        Seguro y puedan ser utilizados en la administración,
                        análisis, cumplimiento y/o auditoría del mismo.
                      </Grid>
                    </Grid>
                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion4}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion4")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Declaro que acepto voluntariamente la contratación de
                        este seguro.
                      </Grid>
                    </Grid> */}
                    <Button
                      type="submit"
                      disabled={!formValid}
                      color="primary"
                      style={{
                        display: "none",
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                      }}
                      variant="contained"
                    >
                       Iniciar el Pago
                    </Button>
                    <Button
                      disabled={this.state.isLoading || !formValid}
                      onClick={() => this.handleClickBotonPagar()}
                      type="button"
                      color="primary"
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                      }}
                      variant="contained"
                    >
                       Iniciar el Pago
                    </Button>
                  </div>
                </form>
              </Zoom>
            )}
          </Grid>
          <Grid className={classes.fondoImagen} item xs={12} sm={12} md={6}>
            <img
              className={classes.iconoCentral}
              src={iconoCentral}
              alt="icono"
            ></img>
          </Grid>
        </Grid>
        <FooterGarnet />

        {this.state.mensajesError.map((e, i) => (
          <Snackbar
            style={{ marginBottom: String(55 * i) + "px" }}
            key={i}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={e.active}
            autoHideDuration={6000}
            variant="error"
            message={e.message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.handleCloseSnackbar(i)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        ))}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  preference_id: state.preference_id,
});
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Landing1))
);
