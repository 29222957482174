import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Button from "@material-ui/core/Button";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import API from "../api/axios";
import Zoom from "@material-ui/core/Zoom";
import regionesComunas from "../data-utils/regiones-comunas";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactDOM from "react-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Loading from "../components/common/Loading";

const queryString = require("query-string");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fondoImagenUrl = require("../images/fondo_mobile.png");
const iconoCentral = require("../images/icono_central.png");
const logoMercadopago = require("../images/mercadopago.svg");
const pdfCondicionesGarantiaExtendida = require("../pdfs/Certificado MACONLINE.pdf");
const pdfCondicionesRobo = require("../pdfs/CP MAC Seguro de Robo Equipos.pdf");

var _ = require("lodash");

const styles = theme => ({
  tituloPrincipal1: {
      fontFamily: "Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "48px",
      lineHeight: "53px",
      color: "#0087C2",
      marginBottom: "1rem"
  },
  tituloPrincipal2: {
      fontFamily: "Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "32px",
      lineHeight: "53px",
      color: "#0087C2",
      marginBottom: "3rem"
  },
  textoDetalle: {
      fontFamily: "Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "26px",
      color: "#474747",
      marginBottom: "1.5rem"
  },
  iconoCentral: {
      width: '200px',
      marginLeft: '-100px',
      [theme.breakpoints.down("sm")]: {
          display: "none"
      }
  },
  fondoImagen: {
      background: `url(${fondoImagenUrl}) no-repeat`,
      backgroundSize: "cover",
      height: '960px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down("sm")]: {
          height: "100vh",
          display: "block",
          position: "fixed",
          top: 0,
          width: "100%",
          backgroundPosition: "center",
      }
  },
  containerTab: {
      padding: "10px 7rem 10px 5rem", 
      display: "flex", 
      flexDirection: "column", 
      alignItems: "flex-start",
      [theme.breakpoints.down("sm")]: {
          padding: "10px 1rem 10px 1rem", 
          height: "initial",
          overflow: "initial",
          overflowX: "initial",
          backgroundColor: "rgba(255,255,255,1)",
          position: "relative",
          zIndex: "1",
          minHeight: "calc(100vh - 160px)",
      }
  }
})

class LandingLoading extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true

        }
    }
    toRetry(){

    }
    componentDidMount(){
        // console.log(window.location.hash);
        // var values = queryString.parse(window.location.hash);
        // console.log(values);

        var newUrl = window.location.hash.replaceAll("/landing-loading?", "");
        var values = queryString.parse(newUrl);
        console.log(values);
        
        if (values.token) {
            API({
              url: "api/v2/confirm",
              method: "POST",
              data: { token: values.token },
            })
              .then((response) => {
                console.log(response);
                window.location.href = response.data;
                // setTimeout(() => {
                //     window.location.href = response;
                //   }, 1500);

              })
              .catch((e) => {
                console.log(e);
                const mensajesError = [
                  {
                    active: true,
                    message:
                      "Lo sentimos, tenemos un error interno. Intente de nuevo mas tarde.",
                  },
                ];
                this.setState({ mensajesError });
              });
          } else {
            console.log('ddddd');
            window.location.href = '/#/landing-error';

          }


    }
    render(){
        const { classes } = this.props;
        return (
            <>
            <Loading loading={this.state.loading} />
                <Topbar />
                    <Grid container spacing={0}>
                        <Grid  item xs={12} sm={12} md={6}>
                            <div className={classes.containerTab}>
                                <Typography style={{marginTop: "2rem"}} className={classes.tituloPrincipal1}>
                                    Estamos procesando tu compra!
                                </Typography>
                                <Typography className={classes.tituloPrincipal2}>
                                Espera unos segundos y serás redirigido al resultado.
                                </Typography>
                                <Typography  className={classes.textoDetalle}>
                                    
                                    <br></br>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className={classes.fondoImagen } item xs={12} sm={12} md={6}>
                            <img className={classes.iconoCentral} src={iconoCentral} alt="icono"></img>
                        </Grid>
                    </Grid>
                <FooterGarnet />
            </>
        );
    }
}

export default withRouter(withStyles(styles)(LandingLoading));
